import React, { useState, useEffect, useRef } from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import { StaticImage } from "gatsby-plugin-image"


const IndexPage = () => {
  const [scrollPercent, setScrollPercent] = useState(0);
  const fixedRef = useRef(null);
  const absoluteRef = useRef(null);
  const [absoluteContentHeight, setAbsoluteContentHeight] = useState(0);

  useEffect(() => {
    const handleFixedScroll = () => {
      const fixedDiv = fixedRef.current;
      const absoluteDiv = absoluteRef.current;
      const fixedContentHeight = fixedDiv.scrollHeight - fixedDiv.clientHeight;
      const scrollPercent = (fixedDiv.scrollTop / fixedContentHeight) * 100;
      const absoluteScrollTop = (scrollPercent / 100) * (absoluteContentHeight - absoluteDiv.clientHeight);
      setScrollPercent(absoluteScrollTop);
    };

    const fixedDiv = fixedRef.current;
    fixedDiv.addEventListener('scroll', handleFixedScroll);
    return () => {
      fixedDiv.removeEventListener('scroll', handleFixedScroll);
    };
  }, [absoluteContentHeight]);

  useEffect(() => {
    const absoluteDiv = absoluteRef.current;
    setAbsoluteContentHeight(absoluteDiv.scrollHeight);
  }, []);
  

  return (
    <Layout classmain='homepage'>
      <Seo
        title='Welcome'
        metaDesciption='Skills in graphic design, motion design, 3d modeling and visualization, ui/ux, video-production, advertisting, vfx; Excellent understanding of user experience design for mobile and the web, technology trends, demonstrable design skills, and ability to show relevant work; E-Commerce experience; Solid grasp of web design principles and a good understanding of front-end web technologies; A team player who can easily adapt in a rapidly changing environment.'
      />
      <div className='box-main'>
      <div className='box-links'>
        <ul>
          <li><a href="#welcome">Welcome</a></li>
          <li><a href="#skills">Skills</a></li>
          <li><a href="#games">Mobile Games</a></li>
          <li><a href="#social">Social</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </div>
      <div className='box-out'>
        <div className='box-fixed custom-scroll' ref={fixedRef}>
          <div className='content box-fixed-in'>
            <div className="box box--1" id="welcome">
              <h2 className="glitch" data-text="nuqneH">Hello 🖖 </h2>
              <p>
                I'm an experienced frontend developer who can take a design and make it work in the browser. I've worked on everything from ad banners to landing pages, and I don't just understand how to do it—I can show you my work in action. If you're looking for someone who can create complex designs and then implement them into code, look no further.
              </p> 
            </div>
            <div className="box box--2" id="skills">
              <h2>My skills 🚀</h2>
              <ul>
                <li>Graphic design using Figma, Photoshop, Illustrator, Cinema 4D, Blender and Procreate</li> 
                <li>Designing websites and responsive app layouts</li> 
                <li>Developing landing pages using HTML/CSS/JS/PHP/GATSBYJS/REACTJS</li>
                <li>Frontend development</li>
                <li>Creating new brand identities, including logotypes, brand elements, and company pages</li>
                <li>Motion production</li>
                <li>Administering websites (Wix, Unbounce, Wordpress, Leadpages, Squarespace, Shopify)</li>
                <li>Knowledge of web analytics tools such as Google Analytics, Google Tag Manager, and Hotjar</li>
                <li>Experience in creative production from idea to realization</li>
                <li>Familiarity with PR, SEO, SMM, and ATL/BTL marketing techniques</li>
                <li>Creating dynamic banners using clean HTML5/JS/CSS or Adobe Animate</li> 
                <li>Full marketing cycle experience</li>
              </ul>
              <div className='box__icons'>
                <img
                    alt='CSS3'
                    className='box__icons--image'
                    src='../images/icons/1.svg'
                />
                <img
                    alt='Javascript'
                    className='box__icons--image'
                    src='../images/icons/2.svg'
                />
                <img
                    alt='HTML5'
                    className='box__icons--image'
                    src='../images/icons/3.svg'
                />
                <img
                    alt='Gatsby'
                    className='box__icons--image'
                    src='../images/icons/4.svg'
                />
                <img
                    alt='React.js'
                    className='box__icons--image'
                    src='../images/icons/5.svg'
                />
                <img
                    alt='AE'
                    className='box__icons--image'
                    src='../images/icons/6.svg'
                />
                <img
                    alt='PS'
                    className='box__icons--image'
                    src='../images/icons/7.svg'
                />
                <img
                    alt='AI'
                    className='box__icons--image'
                    src='../images/icons/8.svg'
                />
                <img
                    alt='Figma'
                    className='box__icons--image'
                    src='../images/icons/9.svg'
                />
                <img
                    alt='Blender'
                    className='box__icons--image'
                    src='../images/icons/10.svg'
                />
                <img
                    alt='Unity'
                    className='box__icons--image'
                    src='../images/icons/11.svg'
                />
              </div>
            </div>
            <div className="box box--2" id="games">
              <h2>Mobile games 🎮</h2>
              <p>My hobby is mobile gaming. I love combining my knowledge from different fields to create small games for enjoyment.</p>
              
              <div className="din__game--markets">
                  <a rel="noreferrer" target='_blank' href="https://twoj.io/"><img src="/images/android.svg"/></a>
                  <a rel="noreferrer" target='_blank' href="https://twoj.io/"><img src="/images/ios.svg"/></a>
              </div>
            </div>
            <div className="box box--3" id="social">
              <h2>Social media 😃</h2>
              <p>You can discover additional information about my professional experience and accomplishments by visiting my social media profiles. I hope to connect with you soon!</p>
              <div className='intro__soc'>
                <a rel="noreferrer" target='_blank' href='https://dribbble.com/adbro'>
                  <img src='/images/dribbble.svg' alt='Dribbble'/>
                </a>
                <a rel="noreferrer" target='_blank' href='https://www.figma.com/@andrewbro'>
                  <img src='/images/figma.svg' alt='Figma'/>
                </a>
                <a rel="noreferrer" target='_blank' href='https://www.linkedin.com/in/andreybobrovnik/'>
                  <img src='/images/linkedin.svg' alt='Linkedin'/>
                </a>
                <a rel="noreferrer" target='_blank' href='https://www.facebook.com/andrey.bobrovnik'>
                  <img src='/images/facebook.svg' alt='Facebook'/>
                </a>
                <a rel="noreferrer" target='_blank' href='https://www.instagram.com/andrey_bobrovnik/'>
                  <img src='/images/ig.svg' alt='Instagram'/>
                </a>
              </div>
            </div>
            <div className="box box--4" id="contact">
              <h2>Get in touch 👯‍♀️</h2>
              <a href='mailto:info@andrewbro.com' className='btn'>info@andrewbro.com</a>
            </div>
          </div>
        </div>
      </div>
      <div className='share'>
        
      </div>
      </div>
      <div className="back-container" ref={absoluteRef}>
        <div className='back-inner' style={{ top: `-${scrollPercent}px` }}>
          <div className="back back--1">
            <div className="back__content-full">
              <StaticImage
                src='../images/spaceman.png'
                className='back__image back__image--v0'
                alt="DIN: Mobile Game"
              />
              <img
                  alt='AdBro'
                  className='back__image back__image--v6'
                  src='../images/cloud.svg'
              />
            </div>
          </div>
          <div className="back back--11">
            <div className="back__content-full">
              <p className='bifont bifont--v1'>Andrew</p>
            </div>
          </div>
          <div className="back back--2">
            <div className="back__content">
              <img
                  alt='AdBro'
                  className='back__image back__image--v1'
                  src='../images/i1.svg'
              />              
              <p className='bifont bifont--v2'>Bro</p>
              <StaticImage
                src='../images/din-g.png'
                className='back__image back__image--v5'
                alt="DIN: Mobile Game"
              />
            </div>
          </div>
          <div className="back back--3">
            <div className="back__content-full">
              <img
                    alt='AdBro'
                    className='back__image back__image--v4'
                    src='../images/like.svg'
                />
                <img
                    alt='AdBro'
                    className='back__image back__image--v7'
                    src='../images/chicken.svg'
                />
            </div>
          </div>
          <div className="back back--4">
            <div className="back__content-full">
              <img
                    alt='AdBro'
                    className='back__image back__image--v2'
                    src='../images/i2.svg'
                />                
                <p className='bifont bifont--v3'>Get in touch</p>
                <StaticImage
                  src='../images/icon.png'
                  className='back__image back__image--v3'
                  alt="AdBro"
                />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
